import { Component } from "react";



class Artist extends Component {
    render() { 
        return (
            <section className="artist padding-top padding-bottom" id="artist">
                <div className="container">
                    <div className="artist__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="artist__thumb" data-aos="fade-right" data-aos-duration="1000">
                                    <img src="assets/images/artist/01.png" alt="Artist Image" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="artist__content" data-aos="fade-left" data-aos-duration="1000">
                                    <h2 className="mb-4">Artist- Alex Smith</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempore ratione natus soluta accusamus expedita harum aliquid ullam? Exin, fugale! Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet adipi.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempore ratione natus soluta accusamus expedita harum aliquid ullam? Exin, fugale! Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet adipi.</p>
                                    <p className="mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempore ratione natus soluta accusamus expedita harum aliquid ullam? Exin, fugale! Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet adipi.</p>
                                    <a href="#" className="default-btn default-btn--rounded">
                                        <span>More About The Artist</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Artist;