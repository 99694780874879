import { Component } from "react";

const title = "Growth Roadmap";
const desc = "Entering The Robotar Society means joining a family full of people who believe in the future of Cryptocurrencies technology.";


let roadmapList = [
    {
        parcent: '10%',
        title: '1.Launch Initiated',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quam inventore. Illum dolorum dolore, quaerat in consectetur vel voluptas excepturi the same creative Provident?',
    },
    {
        parcent: '20%',
        title: '2. Staking',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quam inventore. Illum dolorum dolore, quaerat in consectetur vel voluptas excepturi the same creative Provident?',
    },
    {
        parcent: '40%',
        title: '3.Physical Merch & Live Events',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quam inventore. Illum dolorum dolore, quaerat in consectetur vel voluptas excepturi the same creative Provident?',
    },
    {
        parcent: '60%',
        title: '4.The Robotar',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quam inventore. Illum dolorum dolore, quaerat in consectetur vel voluptas excepturi the same creative Provident?',
    },
    {
        parcent: '10%',
        title: '5.Metaverse',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quam inventore. Illum dolorum dolore, quaerat in consectetur vel voluptas excepturi the same creative Provident?',
    },
]

class Roadmap extends Component {
    render() { 
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="roadmap__wrapper" data-aos="fade-up" data-aos-duration="1000">
                        <div className="row justify-content-center g-5">
                            {roadmapList.map((val, i) => (
                                <div className="col-lg-8" key={i}>
                                    <div className="roadmap__item">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__thumb">
                                                <span>{val.parcent}</span>
                                            </div>
                                            <div className="roadmap__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Roadmap;