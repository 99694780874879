
import { Fragment } from "react";
import {BrowserRouter } from "react-router-dom";
import 'swiper/css';
import AosInit from "./component/layout/aos";

import Footer from './component/layout/footer';
import Header from './component/layout/header';
import About from './component/section/about';
import AboutTwo from './component/section/about-2';
import Artist from './component/section/artist';
import Banner from './component/section/banner';
import Community from './component/section/community';
import Faq from './component/section/faq';
import Roadmap from './component/section/roadmap';
import Team from './component/section/team';

function App() {
	return (
		<BrowserRouter>
			<AosInit />
			<Fragment>
				<Header />
				<Banner />
				<About />
				<AboutTwo />
				<Roadmap />
				<Team />
				<Artist />
				<Community />
				<Faq />
				<Footer />
			</Fragment>
		</BrowserRouter>
	);
}

export default App;
