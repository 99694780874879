import { Component } from "react";

const title = "Join The Robotar";
const desc = "Join a genuine community of 150,000 Robotar!";
const btnText = "Join Our Discord";


class Community extends Component {
    render() { 
        return (
            <section className="community padding-top padding-bottom bg__gradient">
                <div className="container">
                    <div className="comminity__wrapper">
                        <div className="community__content text-center" data-aos="zoom-in" data-aos-duration="1000">
                            <h2>{title}</h2>
                            <p>{desc}</p>
                            <a href="#" className="default-btn default-btn--rounded"><span>{btnText}</span></a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Community;