import { Component } from "react";


class About extends Component {
    render() { 
        return (
            <section className="about padding-top padding-bottom">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__thumb about__thumb--floating" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src="assets/images/art/01.png" alt="About Image" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-left" data-aos-duration="1000">
                                    <h2>7777 Unique NFT Robotar</h2>
                                    <p>Continually negotiate cross-media data and efficient e-business. Compellin engineer just in time synergy via 24/365 channels. Globaly sale quality with functionalities consectetur adipisicing .</p>
                                    <p>Continually negotiate cross-media data and efficient e-business. Compellin engineer just in time synergy via 24/365 channels. Globaly sale quality with functionalities consectetur adipisicing .</p>
                                    <p>Continually negotiate cross-media data and efficient e-business. Compellin engineer just in time synergy via 24/365 channels. Globaly sale quality with functionalities consectetur adipisicing .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;